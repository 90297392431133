// import logger from "redux-logger";
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// defaults to localStorage for web
import thunk from 'redux-thunk';
import 'regenerator-runtime/runtime';

import { BRIDGE_SSO_TILE_URL, BRIDGE_SSO_URL } from '@/routes/app-routes';

import combineReducers from './reducers/index';

const initialState = {};

export const middlewares = [
  thunk,
  // logger
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    storage.removeItem('persist:root:dialog');
    storage.removeItem('persist:root:theme');
    storage.removeItem('persist:root:Session');
    storage.removeItem('persist:root:entities');
    storage.removeItem('persist:root:PatientAssignments');
    state = undefined;
  }
  return combineReducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'Session',
    'theme', // comment when debugging
  ],
  // blacklist: ['theme'] // uncomment when debugging
  // Blacklist (Don't Save Specific Reducers)
  // blacklist: ["counterReducer"]
};

if (
  location.pathname.includes(BRIDGE_SSO_URL) ||
  location.pathname.includes(BRIDGE_SSO_TILE_URL)
) {
  storage.removeItem('persist:root');
}

export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);
