import { DispatchProp } from 'react-redux';
import { toast } from 'sonner';

import {
  setCurrentPatient,
  setLoginSuccess,
  storeSessionData,
} from '@/old/state/actions/Login/login_actions';
import { identifyIntercom } from '@/old/utils/thirdPartyAPIs/intercom';
import { SegmentIO } from '@/old/utils/thirdPartyAPIs/segment';
import { BRIDGE_TILES_URLS } from '@/routes/app-routes';
import { UserTypeEnum } from '@/schema';

import { identifySentryUser } from './sentry';

export type UnSerializedProvider = {
  token: string;
  api: { sendbird_session_token: string; sendbird_api_token: string };
  user: {
    id: number;
    email: string;
    user_type: string;
    last_name: string;
    first_name: string;
  };
};

export const initAuthState = (
  dispatch: DispatchProp['dispatch'],
  provider: UnSerializedProvider,
) => {
  dispatch(storeSessionData(provider));

  const { user } = provider;

  // TODO: Potential artifact, check if this is still needed
  if (user.user_type === UserTypeEnum.Patient) {
    dispatch(setCurrentPatient(user.id));
  }

  const fullName = `${user.first_name} ${user.last_name}`;

  identifyIntercom(user);
  SegmentIO.identify(user);
  identifySentryUser({
    id: user.id,
    email: user.email,
    fullName,
  });

  dispatch(setLoginSuccess(user));

  // Don't show the welcome back message on the bridge tiles
  if (
    !BRIDGE_TILES_URLS.some((url) => window.location.pathname.includes(url))
  ) {
    toast.success(`👋🏽 Welcome back ${fullName} 😀`);
  }
};
