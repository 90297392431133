import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';

import { authTokenSelector } from '@/old/state/reducers/login_reducer';
import { LOGIN_URL } from '@/routes/app-routes';

const PrivateRoute = () => {
  const location = useLocation();
  const token: string | undefined = useSelector(authTokenSelector);

  if (!token) {
    const state = { from: location.pathname };
    return <Navigate to={LOGIN_URL} state={state} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
