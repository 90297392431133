/* tslint:disable */
/* eslint-disable */
/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { AggregationEnum } from './AggregationEnum';
import {
  AggregationEnumFromJSON,
  AggregationEnumFromJSONTyped,
  AggregationEnumToJSON,
} from './AggregationEnum';

/**
 *
 * @export
 * @interface TractorscopeUrlReq
 */
export interface TractorscopeUrlReq {
  /**
   *
   * @type {Array<string>}
   * @memberof TractorscopeUrlReq
   */
  clinics: Array<string>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TractorscopeUrlReq
   */
  filters?: { [key: string]: string };
  /**
   *
   * @type {AggregationEnum}
   * @memberof TractorscopeUrlReq
   */
  aggregation?: AggregationEnum;
  /**
   *
   * @type {string}
   * @memberof TractorscopeUrlReq
   */
  dateRange?: string;
}

/**
 * Check if a given object implements the TractorscopeUrlReq interface.
 */
export function instanceOfTractorscopeUrlReq(value: object): boolean {
  if (!('clinics' in value)) return false;
  return true;
}

export function TractorscopeUrlReqFromJSON(json: any): TractorscopeUrlReq {
  return TractorscopeUrlReqFromJSONTyped(json, false);
}

export function TractorscopeUrlReqFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TractorscopeUrlReq {
  if (json == null) {
    return json;
  }
  return {
    clinics: json['clinics'],
    filters: json['filters'] == null ? undefined : json['filters'],
    aggregation:
      json['aggregation'] == null
        ? undefined
        : AggregationEnumFromJSON(json['aggregation']),
    dateRange: json['date_range'] == null ? undefined : json['date_range'],
  };
}

export function TractorscopeUrlReqToJSON(
  value?: TractorscopeUrlReq | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    clinics: value['clinics'],
    filters: value['filters'],
    aggregation: AggregationEnumToJSON(value['aggregation']),
    date_range: value['dateRange'],
  };
}
